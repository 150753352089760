import { Image, Input, Tabs, Typography, Select, Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import emptyImage from '../../Assets/schedule/Group 10647.svg'
import './styles.css'
import ChooseAnnouncement from './modal/chooseAnnouncementModal'


import styled from 'styled-components'
import CreateAnnouncement from './modal/createAnnouncement'
import AnnounceScheduleCard from './announceScheduleCard'
import AnnounceDraftCard from './announceDraftCard'
import AnnouncedCard from './AnnouncedCard'
import { connect } from 'react-redux'
import { announcementType, fetchAnnouncement, fetchStandards, setSearchFilter, setSortFilter, setAnnouncementData, storeAnnouncePage, switchAnnounceData, setDraftPage, setSchedulePage, setAnnounceKey, setStdFilterValue } from 'actions/announcement'
import PageDataLoader from 'components/pageDataLoader'
import CreateAnnouncementModal from './modal/createAnnouncmentModal'
import PageHeader from 'components/pageHeader';
import { Heading } from 'components/Typography'
import { Button } from 'components/Button'
import { ArrowLeftOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons'
import { InputSearch } from 'components/customInputs'
import { useHistory } from 'react-router'
import CloneAnnouncement from './modal/cloneModal'
import Mask_Group_48 from "../../Assets/images/Mask_Group_48.svg"
import Mask_Group_50 from "../../Assets/images/Mask_Group_50.svg"
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { NodataCard } from 'pages/classRoom'


const { TabPane } = Tabs
const { Text } = Typography


const AnnouncementMain = styled.div`
    width: 100%;
    height: 580px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: #EDEDED;
    border-radius: 16px;
`;

const AnnouncementEmpty = styled.div`
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const AnnoucnementAdminContent = styled.div`
    width: 100%;
    display:flex;
    flex-direction:column;

`;

const AnnouncementContentFilter = styled.div`
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom:10px;
`;

const AnnouncementContentMain = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;

`;

const AnnouncementContentSchedule = styled.div`
    width:100%;
    margin-top:10px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    flex-wrap: wrap;
`;

const AnnouncedContent = styled.div`
    width:100%;
    display:flex;
    margin-top:20px;
    flex-direction:row;
    align-items:center;
    flex-wrap: wrap;
    overflow: auto;

`;
const AnnouncementContentDraft = styled.div`
    // margin-top:10px;
    width:100%;
    // display:flex;
    // flex-direction:row;
  
    // flex-wrap: wrap;
     height: 65vh
    // overflow: auto;
`;
const AnnouncementContentScheduleCard = styled.div`
    // margin-top:10px;
    width:100%;
    // display:flex;
    // flex-direction:row;
  
    // flex-wrap: wrap;
     height: 65vh
    // overflow: auto;
`;
const AnnouncementContentDrafts = styled.div`
    margin-top:10px;
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    flex-wrap: wrap;
    //  height: 60vh;
    overflow: auto;
`;

const AnnouncementContentAnnounce = styled.div`
    margin-top:10px;
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    flex-wrap: wrap;
    overflow: auto;
`;

const AnnouncementContentAnnounced = styled.div`
    // margin-top:10px;
    width:100%;
    // display:flex;
    // flex-direction:row;
  
    // flex-wrap: wrap;
     height: 73vh
    // overflow: auto;
`;

const data = true


const AnnoucnementAdmin = ({ setDraftPage, setSchedulePage, switchAnnounceData, storePageData, fetchAnnouncement, announcement, setSortFilter, setSearchFilter, announcementType, setAnnouncementData, storeAnnouncePage, setAnnounceKey, storeKey, standardList, setStdFilterValue, selectedStd }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isCloneModal, setIsCloneModal] = useState(false)
    const [chooseModal, setChooseModal] = useState(false)
    const [isAnnouncement, setIsAnnouncement] = useState(false)
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState([])
    const [key, SetKey] = useState("")
    const [isClear, setIsClear] = useState('')


    const [pageLoader, togglePageLoader] = useState(false);
    const location = useLocation();
    const history = useHistory()

    useEffect(() => {
        setStdFilterValue(null)
        storeAnnouncePage(1)
        setDraftPage(1)
        setSchedulePage(1)

        togglePageLoader(true)
        if (location?.state?.key) {
            togglePageLoader(true)
            setAnnounceKey('3')
            setSort(["STUDENT", "All"])
            setStdFilterValue(location?.state?.key1)
            fetchAnnouncement('', ["STUDENT", "All"], () => togglePageLoader(false))
        } else {
            togglePageLoader(true)
            setAnnounceKey('1')
            fetchAnnouncement('', '', () => togglePageLoader(false))
        }
    }, [])

    const handleTab = (key) => {
        handleStdFilter('')
        setSort([])
        setAnnounceKey(key)
        togglePageLoader(true)
        fetchAnnouncement('', '', () => togglePageLoader(false))
        setIsClear('')
        setSearch('')
    }

    const handleSearch = (value) => {
        setIsClear(value)
        setSearch(value)
        setSearchFilter(value)
        storeAnnouncePage(1)
        setDraftPage(1)
        setSchedulePage(1)
        togglePageLoader(true)
        fetchAnnouncement(value, sort, () => togglePageLoader(false))
    }
    const handleClearSelect = () => {
        setSort([]);
        setStdFilterValue(null)
    }
    const handleSort = (value) => {
        setIsClear('')
        setSearch('')
        setSort(value)
        setSortFilter(value)
        storeAnnouncePage(1)
        setDraftPage(1)
        setSchedulePage(1)
        if (!sort?.includes("STUDENT") || !sort) {
            setStdFilterValue(null)
        }
        togglePageLoader(true)
        fetchAnnouncement('', value, () => togglePageLoader(false))
    }
    const handleStdFilter = (value) => {
        setStdFilterValue(value)
        storeAnnouncePage(1)
        setDraftPage(1)
        setSchedulePage(1)
        togglePageLoader(true)
        fetchAnnouncement(search, '', () => togglePageLoader(false))
    }

    const handlePageination = (value) => {
        storeAnnouncePage(value)
        togglePageLoader(true)
        fetchAnnouncement(search, sort, () => togglePageLoader(false))
    }
    const handleSchedulePagination = (value) => {
        setSchedulePage(value)
        togglePageLoader(true)
        fetchAnnouncement(search, sort, () => togglePageLoader(false))

    }
    const handleDraftPageination = (value) => {
        setDraftPage(value)
        togglePageLoader(true)
        fetchAnnouncement(search, sort, () => togglePageLoader(false))
    }

    const actions = [
        <Button type="primary" onClick={() => { setChooseModal(true), setAnnouncementData(''), setSearch('') }} style={{}} icon={<PlusCircleOutlined />}>CREATE ANNOUNCEMENT</Button>
    ]
    return (
        <div>
            {location?.state?.key == "fromClassroom" ?
                <div style={{ display: "flex", justifyContent: "space-between", height: 50, borderBottom: "1px solid #E6E6E6" }}>
                    <div style={{ fontSize: "2em", fontWeight: 700, display: "flex", flexDirection: "row" }}>
                        <ArrowLeftOutlined
                            style={{ marginTop: "15px", fontSize: "20px" }}
                            onClick={() => {
                                history.goBack();
                            }} />
                        <div style={{ marginLeft: "15px" }}>{"Annoucement"}</div>
                    </div>
                    <Button type="primary" onClick={() => { setChooseModal(true), setAnnouncementData(''), setSearch('') }} style={{}} icon={<PlusCircleOutlined />}>CREATE ANNOUNCEMENT</Button>
                </div>
                :
                <div style={{marginBottom:50}}>
                    <PageHeader title='Annoucements' actions={actions}/>
                </div>
            }
            <div>

                <Tabs activeKey={storeKey}
                    onChange={key => handleTab(key)} tabBarGutter={50} tabBarStyle={{ color: "#594099" }}>
                    <TabPane tab={
                        "DRAFT (" + (storeKey == '1' ? `${announcement?.filteredMeta?.totalItems}/` : '') + announcement?.meta?.draft?.totalItems + ')'
                    } key='1' >
                        <AnnouncementContentFilter>
                            <div style={{ width: "40%", display: 'flex', marginLeft: "10px" }} id="testHomeDropdown" >
                                <Select
                                    mode='multiple'
                                    getPopupContainer={(trigger) => trigger.parentElement}
                                    allowClear
                                    placeholder="Filter By"
                                    style={{ width: '60%', textAlign: 'left', marginRight: 30 }}
                                    onChange={handleSort}
                                    value={sort != [] != '' ? sort : []}
                                    onClear={handleClearSelect}
                                >
                                    <Option value={'All'}>All</Option>
                                    <Option value={'TEACHER'}>Teacher</Option>
                                    <Option value={'STUDENT'}>Student</Option>
                                    <Option value={'DATA OPERATOR'}>Data Operator</Option>
                                    <Option value={'OPERATION'}>Operation</Option>
                                </Select>
                                {sort?.includes("STUDENT") ?
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        // allowClear
                                        style={{ width: "70%" }}
                                        placeholder="Filter By Class"
                                        onChange={handleStdFilter}
                                        value={selectedStd}
                                    >
                                        {standardList?.length ? standardList?.map((item, index) => (
                                            <Option key={index} value={item?.id}>{item?.std} - {item?.section}</Option>
                                        ))
                                            :
                                            null
                                        }
                                    </Select>
                                    :
                                    <></>
                                }
                            </div>
                            <div style={{ width: "30%" }}>
                                <InputSearch value={isClear} placeholder="Search Title" style={{ width: '80%' }} allowClear onClear={() => { handleSearch('') }} onSearch={(value) => { handleSearch(value) }} onEnter={(e) => { handleSearch(e) }} />
                            </div>
                        </AnnouncementContentFilter>
                        <AnnoucnementAdminContent>


                            {announcement?.meta?.draft?.totalItems != 0 ?
                                <div className='m-t-20 m-b-20'>
                                    {announcement?.draft?.length != 0 ?
                                        <AnnouncementContentDraft>

                                            <AnnouncementContentDrafts>
                                                {announcement?.draft?.map((item) => (
                                                    <AnnounceDraftCard
                                                        draftItem={item}
                                                        togglePageLoader={togglePageLoader}
                                                        SetKey={SetKey}
                                                        setIsAnnouncement={setIsAnnouncement}
                                                        setIsSearch={setIsClear}
                                                        setSort={setSort}
                                                    />
                                                ))}
                                            </AnnouncementContentDrafts>
                                        </AnnouncementContentDraft> : <div><NodataCard /></div>}
                                </div> :
                                (<>
                                    <div>
                                        <AnnouncementMain>
                                            <div style={{ textAlign: "center", fontSize: "21px", color: "#191919", fontWeight: "bold", }}>Get started with annoucement</div>


                                            <div style={{ marginTop: "20px", }}>
                                                <div className='shadow-box p-20 position-relative cursor-pointer' style={{ width: '100%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250 }} onClick={() => { setIsModalVisible(true); close(false) }}>
                                                    <div style={{ width: "100%", display: "flex" }}>
                                                        <img src={Mask_Group_48} />
                                                        <div style={{ marginLeft: "20px", width: "70%" }} >
                                                            <div style={{ color: "#191919", fontSize: "17px", fontWeight: "bold" }}>Create text announcement</div>
                                                            <div style={{ color: "#636363", fontSize: "13px", }}>Create a formal written notice of certain events which has happened or going to happen</div>
                                                        </div>

                                                        <div style={{ marginTop: "20px", fontSize: "20px" }}><RightOutlined
                                                        /></div>
                                                    </div>
                                                </div>

                                                <div className='shadow-box p-20 position-relative cursor-pointer' style={{ width: '100%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250, marginTop: "10px" }} onClick={() => { setIsAnnouncement(true); close(false) }}>
                                                    <div style={{ width: "100%", display: "flex" }}>
                                                        <img src={Mask_Group_50} />
                                                        <div style={{ marginLeft: "20px", width: "70%" }}>
                                                            <div style={{ color: "#191919", fontSize: "15px", fontWeight: "bold" }}>Create attachment announcement</div>
                                                            <div style={{ color: "#636363", fontSize: "13px" }}>Create and easily send out announcement with attachments (doc, xlsx, pdf, png, jpg, etc.)</div>
                                                        </div>
                                                        <div style={{ marginTop: "20px", fontSize: "20px" }}><RightOutlined /></div>

                                                    </div>
                                                </div>
                                            </div>

                                        </AnnouncementMain>


                                    </div>
                                </>)}

                            <div style={{ display: "flex", justifyContent: "flex-end",marginRight:"110px" }}>
                                {announcement?.draft?.length != 0 ?
                                    <Pagination
                                        size='small'
                                        current={announcement?.filteredMeta?.currentPage}
                                        defaultCurrent={1}
                                        total={announcement?.filteredMeta?.totalPages * 10}
                                        onChange={handleDraftPageination}
                                        showSizeChanger={false}
                                    /> : null}
                            </div>
                        </AnnoucnementAdminContent>
                        {/* // } */}
                    </TabPane>
                    {console.log("AA-abcdf", announcement?.meta?.schedule?.totalItems)}
                    <TabPane
                        tab={
                            "SCHEDULED (" +
                            (storeKey === '2'
                                ? `${announcement?.filteredMeta?.totalItems || 0}/`
                                : '') +
                            `${announcement?.meta?.schedule?.totalItems || 0})`
                        }
                        key='2' >
                        <AnnouncementContentFilter>

                            <div style={{ width: "40%", display: 'flex', marginLeft: 20 }} id="testHomeDropdown" >
                                <Select
                                    mode='multiple'
                                    getPopupContainer={(trigger) => trigger.parentElement}
                                    allowClear
                                    placeholder="Filter By"
                                    style={{ width: '60%', textAlign: 'left', marginRight: 30 }}
                                    onChange={handleSort}
                                    value={sort != [] ? sort : []}
                                    onClear={handleClearSelect}
                                >
                                    <Option value={'All'}>All</Option>
                                    <Option value={'TEACHER'}>Teacher</Option>
                                    <Option value={'STUDENT'}>Student</Option>
                                    <Option value={'DATA OPERATOR'}>Data Operator</Option>
                                    <Option value={'OPERATION'}>Operation</Option>
                                </Select>
                                {sort?.includes("STUDENT") ?
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        // allowClear
                                        style={{ width: "70%" }}
                                        placeholder="Filter By Class"
                                        onChange={handleStdFilter}
                                        value={selectedStd}
                                    >
                                        {standardList?.length ? standardList?.map((item, index) => (
                                            <Option key={index} value={item?.id}>{item?.std} - {item?.section}</Option>
                                        ))
                                            :
                                            null
                                        }
                                    </Select>
                                    :
                                    <></>
                                }
                            </div>
                            <div style={{ width: "30%" }}>
                                <InputSearch value={isClear} placeholder="Search Title" style={{ width: '80%' }} allowClear onClear={() => { handleSearch('') }} onSearch={(value) => { handleSearch(value) }} onEnter={(e) => { handleSearch(e) }} />
                            </div>
                        </AnnouncementContentFilter>
                        <AnnoucnementAdminContent>
                            {console.log("AA-sort", typeof sort)}
                            {announcement?.draft?.length != 0 || announcement?.schedule?.length != 0 ?
                                <AnnouncementContentMain>
                                    {/* {announcement?.schedule?.length != 0 ?
                                        <div className='m-t-20'>

                                            <AnnouncementContentScheduleCard>
                                                <AnnouncementContentDrafts>
                                                    {announcement?.schedule?.map((item) => (
                                                        <AnnounceScheduleCard
                                                            scheduleItem={item}
                                                            togglePageLoader={togglePageLoader}
                                                            SetKey={SetKey}
                                                            setIsAnnouncement={setIsAnnouncement}
                                                            setIsSearch={setIsClear}
                                                            setSort={setSort}

                                                        />
                                                    ))}
                                                </AnnouncementContentDrafts>
                                            </AnnouncementContentScheduleCard>
                                        </div> : <div>
                                            <AnnouncementMain>
                                                <div style={{ textAlign: "center", fontSize: "21px", color: "#191919", fontWeight: "bold", }}>Get started with annoucement</div>


                                                <div style={{ marginTop: "20px", }}>
                                                    <div className='shadow-box p-20 position-relative cursor-pointer' style={{ width: '100%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250 }} onClick={() => { setIsModalVisible(true); close(false) }}>
                                                        <div style={{ width: "100%", display: "flex" }}>
                                                            <img src={Mask_Group_48} />
                                                            <div style={{ marginLeft: "20px", width: "70%" }} >
                                                                <div style={{ color: "#191919", fontSize: "17px", fontWeight: "bold" }}>Create text announcement</div>
                                                                <div style={{ color: "#636363", fontSize: "13px", }}>Create a formal written notice of certain events which has happened or going to happen</div>
                                                            </div>

                                                            <div style={{ marginTop: "20px", fontSize: "20px" }}><RightOutlined
                                                            /></div>
                                                        </div>
                                                    </div>

                                                    <div className='shadow-box p-20 position-relative cursor-pointer' style={{ width: '100%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250, marginTop: "10px" }} onClick={() => { setIsAnnouncement(true); close(false) }}>
                                                        <div style={{ width: "100%", display: "flex" }}>
                                                            <img src={Mask_Group_50} />
                                                            <div style={{ marginLeft: "20px", width: "70%" }}>
                                                                <div style={{ color: "#191919", fontSize: "15px", fontWeight: "bold" }}>Create attachment announcement</div>
                                                                <div style={{ color: "#636363", fontSize: "13px" }}>Create and easily send out announcement with attachments (doc, xlsx, pdf, png, jpg, etc.)</div>
                                                            </div>
                                                            <div style={{ marginTop: "20px", fontSize: "20px" }}><RightOutlined /></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </AnnouncementMain>
                                        </div>} */}
                                    {announcement?.schedule?.length != 0 ?
                                        <div className='m-t-20'>

                                            <AnnouncementContentScheduleCard>
                                                <AnnouncementContentDrafts>
                                                    {announcement?.schedule?.map((item) => (
                                                        <AnnounceScheduleCard
                                                            scheduleItem={item}
                                                            togglePageLoader={togglePageLoader}
                                                            SetKey={SetKey}
                                                            setIsAnnouncement={setIsAnnouncement}
                                                            setIsSearch={setIsClear}
                                                            setSort={setSort}

                                                        />
                                                    ))}
                                                </AnnouncementContentDrafts>
                                            </AnnouncementContentScheduleCard>
                                        </div> :
                                        <div><NodataCard /></div>
                                      
                                    }
                                    {announcement?.schedule?.length != 0 ?
                                        <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                            <Pagination
                                                size='small'
                                                current={announcement?.filteredMeta?.currentPage}
                                                defaultCurrent={1}
                                                total={announcement?.filteredMeta?.totalPages * 10}
                                                onChange={handleSchedulePagination}
                                                showSizeChanger={false}
                                            />

                                        </div> : null}
                                </AnnouncementContentMain>
                                :
                                <div>
                                    <AnnouncementMain>
                                        <AnnouncementEmpty>
                                            <div style={{ textAlign: "center", fontSize: "21px", color: "#191919", fontWeight: "bold", }}>Create annoucement</div>


                                            <div style={{ marginTop: "20px", }}>
                                                <div className='shadow-box p-20 position-relative cursor-pointer' style={{ width: '100%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250 }} onClick={() => { setIsModalVisible(true); close(false) }}>
                                                    <div style={{ width: "100%", display: "flex" }}>
                                                        <img src={Mask_Group_48} />
                                                        <div style={{ marginLeft: "20px", width: "70%" }} >
                                                            <div style={{ color: "#191919", fontSize: "17px", fontWeight: "bold" }}>Create text announcement</div>
                                                            <div style={{ color: "#636363", fontSize: "13px", }}>Create a formal written notice of certain events which has happened or going to happen</div>
                                                        </div>
                                                        <div style={{ marginTop: "20px", fontSize: "20px" }}><RightOutlined /></div>
                                                    </div>
                                                </div>

                                                <div className='shadow-box p-20 position-relative cursor-pointer' style={{ width: '100%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250, marginTop: "10px" }} onClick={() => { setIsAnnouncement(true); close(false) }}>
                                                    <div style={{ width: "100%", display: "flex" }}>
                                                        <img src={Mask_Group_50} />
                                                        <div style={{ marginLeft: "20px", width: "70%" }}>
                                                            <div style={{ color: "#191919", fontSize: "15px", fontWeight: "bold" }}>Create attachment announcement</div>
                                                            <div style={{ color: "#636363", fontSize: "13px" }}>Create and easily send out announcement with attachments (doc, xlsx, pdf, png, jpg, etc.)</div>
                                                        </div>
                                                        <div style={{ marginTop: "20px", fontSize: "20px" }}><RightOutlined /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </AnnouncementEmpty>
                                    </AnnouncementMain>
                                </div>
                            }
                        </AnnoucnementAdminContent>
                        {/* // } */}
                        {console.log("AAAAA-asa", announcement?.meta?.announced?.totalItems)}
                    </TabPane>
                    <TabPane
                        tab={
                            "ANNOUNCED (" + (storeKey == '3' ? `${announcement?.filteredMeta?.totalItems}/` : '') + announcement?.meta?.announced?.totalItems + ')'
                        }
                        key='3'
                    >
                        <AnnoucnementAdminContent>
                            <AnnouncementContentFilter>
                                <div style={{ width: "40%", display: 'flex', marginLeft: 10 }} id="area">
                                    <Select
                                        mode='multiple'
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        allowClear
                                        style={{ width: "60%", marginRight: 30 }}
                                        placeholder="Filter By"
                                        onChange={handleSort}
                                        value={sort != [] ? sort : []}
                                        onClear={handleClearSelect}
                                    >
                                        <Option value='All'>All</Option>
                                        <Option value='TEACHER'>Teacher</Option>
                                        <Option value='STUDENT'>Student</Option>
                                        <Option value='DATA OPERATOR'>Data Operator</Option>
                                        <Option value='OPERATION'>Operation</Option>
                                    </Select>
                                    {sort?.includes("STUDENT") ?
                                        <Select
                                            getPopupContainer={(trigger) => trigger.parentElement}
                                            // allowClear
                                            style={{ width: "70%" }}
                                            placeholder="Filter By Class"
                                            onChange={handleStdFilter}
                                            value={selectedStd}
                                        >

                                            {standardList?.length ? standardList?.map((item, index) => (
                                                <Option key={index} value={item?.id}>{item?.std} - {item?.section}</Option>
                                            ))
                                                :
                                                null
                                            }

                                        </Select>
                                        :
                                        <></>
                                    }
                                </div>
                                <div style={{ width: "30%" }}>
                                    <InputSearch value={isClear} placeholder="Search Title" style={{ width: '80%' }} allowClear onClear={() => { handleSearch('') }} onSearch={(value) => { handleSearch(value) }} onEnter={(e) => { handleSearch(e) }} />
                                </div>

                            </AnnouncementContentFilter>


                            {announcement?.meta?.announced?.totalItems != 0 ?
                                (<> <div>

                                    <AnnouncementContentAnnounced>
                                        <AnnouncementContentAnnounce>
                                            {announcement?.announced?.map((item) => (
                                                <AnnouncedCard
                                                    announcedItem={item}
                                                    SetKey={SetKey}
                                                    setIsAnnouncement={setIsAnnouncement}
                                                    setIsSearch={setIsClear}
                                                    setSort={setSort}
                                                    togglePageLoader={togglePageLoader}
                                                />
                                            ))}
                                        </AnnouncementContentAnnounce>

                                    </AnnouncementContentAnnounced>
                                </div>
                                    <div style={{ position: "absolute", bottom: 0, marginLeft: "70%", marginTop: "5px" }}>
                                        <Pagination
                                            size='small'
                                            current={announcement?.filteredMeta?.currentPage}
                                            defaultCurrent={1}
                                            total={announcement?.filteredMeta?.totalPages * 10}
                                            onChange={handlePageination}
                                            showSizeChanger={false}
                                        />
                                    </div>
                                </>)


                                :
                                <AnnouncementMain>
                                    <AnnouncementEmpty>
                                        <Image
                                            src={emptyImage}
                                            preview={false}
                                        />
                                        <Text style={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'roboto' }}>No announcements created yet!</Text>
                                        <Text style={{ color: "#636363", fontSize: '12px', fontFamily: 'roboto', textAlign: 'center', marginBottom: '8px' }}>There are no announcements created yet.</Text>
                                    </AnnouncementEmpty>
                                </AnnouncementMain>}
                        </AnnoucnementAdminContent>
                    </TabPane>
                </Tabs>
                <PageDataLoader visible={pageLoader} />
            </div>
            {(chooseModal && <ChooseAnnouncement
                chooseModal={chooseModal}
                setChooseModal={setChooseModal}
                setIsAnnouncement={setIsAnnouncement}
                close={() => { setChooseModal(false) }}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                announcementType={announcementType}
                SetKey={SetKey}
                key={key}



            />)}
            {isAnnouncement &&
                <CreateAnnouncementModal
                    close={() => { setChooseModal(false) }}
                    isAnnouncement={isAnnouncement}
                    setIsAnnouncement={setIsAnnouncement}
                    SetKey={SetKey}
                    key={key}
                    togglePageLoader={togglePageLoader}
                    fetchAnnouncement={fetchAnnouncement}
                    setSort={setSort}
                />
            }
            {(isModalVisible && <CreateAnnouncement setIsSearch={setIsClear} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} edit='' togglePageLoader={togglePageLoader} SetKey={SetKey} key={key} setSort={setSort} />)}
            {(isCloneModal && <CloneAnnouncement isCloneModal={isCloneModal} setIsCloneModal={setIsCloneModal} setIsModalVisible={setIsModalVisible} togglePageLoader={togglePageLoader} SetKey={SetKey} edit='' kye={key} setSort={setSort} />)}

        </div>
    )
}


const mapStateToProps = (state) => {
    const {
        announcement, storePageData, storeKey, standardList, selectedStd
    } = state.announcement;
    return {
        announcement, storePageData, storeKey, standardList, selectedStd
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchAnnouncement: (search, sort, callback) => dispatch(fetchAnnouncement(search, sort, callback)),
    setSortFilter: (sort) => dispatch(setSortFilter(sort)),
    setSearchFilter: (search) => dispatch(setSearchFilter(search)),
    announcementType: (key, val) => dispatch(announcementType(key, val)),
    setAnnouncementData: (res) => dispatch(setAnnouncementData(res)),
    storeAnnouncePage: (res) => dispatch(storeAnnouncePage(res)),
    switchAnnounceData: (res) => dispatch(switchAnnounceData(res)),
    setDraftPage: (res) => dispatch(setDraftPage(res)),
    setSchedulePage: (res) => dispatch(setSchedulePage(res)),
    setAnnounceKey: (val) => dispatch(setAnnounceKey(val)),
    setStdFilterValue: (val) => dispatch(setStdFilterValue(val))


});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(AnnoucnementAdmin);
