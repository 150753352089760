import moment from "moment"
import { Api } from "services"

export const GET_STANDARDS = 'GET_STANDARDS'
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT'
export const SET_SORT_FILTER = 'SET_SORT_FILTER'
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER'
export const POST_ANNOUNCEMENT_DATA = "POST_ANNOUNCEMENT_DATA"
export const ANNOUNCEMENT_TYPE = "ANNOUNCEMENT_TYPE"
export const STORE_ANNOUNCE_PAGE="STORE_ANNOUNCE_PAGE"
export const SWITCH_ANNOUNCEMENT_DATA="SWITCH_ANNOUNCEMENT_DATA"
export const SET_DRAFT_PAGE="SET_DRAFT_PAGE"
export const SET_SCHEDULE_PAGE="SET_SCHEDULE_PAGE"
export const SET_ANNOUNCE_KEY="SET_ANNOUNCE_KEY";
export const SET_STD_FILTER_VALUE = "SET_STD_FILTER_VALUE"


export const fetchAnnouncement = (search, sort, callback) => {
  return (dispatch,getState) => {
    const { storePageData,draftPage,schedulePage,storeKey, selectedStd } = getState().announcement;
    console.log("AAAA-store",sort);
    let params = {
      search: search ? search : '',
      target: sort ? sort : selectedStd ? "STUDENT" : '',
      type:storeKey == '' || storeKey == '1' ? 'DRAFT' : storeKey == '2' ? 'SCHEDULE' : storeKey == '3' ? 'ANNOUNCENOW' :'DRAFT',
      page :storeKey == '' || storeKey == '1'  ? draftPage :  storeKey == '2' ? schedulePage  : storeKey == '3' ? storePageData : draftPage,
      standardIds : selectedStd ? [selectedStd] : []
    };
    // const url = `/announcement-new?search`
    return new Promise((resolve, reject) => {
      Api.get(`/announcement-new/announcements-by-type`).params(params)
        .send((response, error) => {
          console.log("AA-callBAkkk",callback);
          if (callback) {
            callback()
          }
          if (response) {
            dispatch(getAnnouncement(response))
            dispatch(getStandards(response.standards))
          }
        })
    })
  }
}

export const postAnnouncemnt = (object, callback, successCallback) => {
  return (dispatch) => {
    console.log("AA-onject",object);
    const url = `/announcement-new`
    if (object?.typeAnnouncement === 'text') {
      
      return new Promise((resolve, reject) => {

        Api.post(url, object)
          .send((response, error) => {
            if (callback) {
              callback();
            }
            if (response !== undefined &&   response?.show?.type !== "error") {
              if (successCallback) {
                successCallback();
              }
              // dispatch(storeAttendanceProfileTabsData(response));
            }
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        Api.post(url)
          .upload(object, (percent, response) => {
            resolve();
            if (callback) {
              callback();
            }
            console.log("AAAAA-response",response?.data?.show?.type);
            if (response !== undefined &&   response?.data?.show?.type !== "error") {
              if(successCallback){
                successCallback();

              }
              
              // dispatch(storeAttendanceProfileTabsData(response));
            }

          })
        // .send((response, error) => {
        //   resolve();
        //   if (response) {
        //     dispatch(fetchAnnouncement());
        //   }
        //   // dispatch(holidayLoader(false));
        // });
      });
    }

  }
}

export const postAnnouncementData = (isSelectValue, std, type, date, time, file,callback = null,successCallback = null) => {
  const url = `/announcement-new`;
  return (dispatch, getState) => {
    const { storeAnnouncementData, standardList } = getState().announcement;
    const params = {
      title: storeAnnouncementData,
      target: isSelectValue,
      standardIds: std,
      type: type,
      date: date ? date : moment().format('YYYY-MM-DD'),
      time:  time ? time : moment().format('HH:mm'),
      files: [...file],
      typeAnnouncement: "file"
    };
    return new Promise((resolve, reject) => {
      Api.post(url)
        .upload(params, (percent, response) => {
          console.log("AA-response",response);
          resolve();
          console.log("AA-calbackkkk",callback);
          if (callback) {
            callback();

          }
console.log("AA-A",response?.data?.show?.type != 'error');
          if (response !== undefined &&   response?.data?.show?.type !== 'error') {
          console.log("AA-calbackkkk1",successCallback);

            if(successCallback){
              successCallback();
            }

          }

        })
      // .send((response, error) => {
      //   resolve();
      //   if (response) {
      //     dispatch(fetchAnnouncement());
      //   }
      //   // dispatch(holidayLoader(false));
      // });
    });

  };
};



export const deleteAnnouncement = (id, callback, successCallback) => {
 
  return (dispatch) => {

    const url = `/announcement-new/delete-announcement?id=${id}`
    return new Promise((resolve, reject) => {
      Api.delete(url)
        .send((response, error) => {
          if (response) {
            dispatch(fetchAnnouncement());
            successCallback()
          }
          else {
            callback()
          }
        })
    })
  }
}

export const fetchNotification = () => {
  return (dispatch) => {
    const url = `/notification`
    return new Promise((resolve, reject) => {
      Api.get(url)
        .send((response, error) => {
          // if(response){
          // }
        })
    })
  }
}


export const getStandards = (std) => ({
  type: GET_STANDARDS,
  std
})

export const getAnnouncement = (announcement) => ({
  type: GET_ANNOUNCEMENT,
  announcement
})

export const setSortFilter = (sortData) => ({
  type: SET_SORT_FILTER,
  sortData
})

export const setSearchFilter = (search) => ({
  type: SET_SEARCH_FILTER,
  search
})
export const setAnnouncementData = (res) => ({
  type: POST_ANNOUNCEMENT_DATA,
  res
})

export const announcementType = (key,val) => ({
  type: ANNOUNCEMENT_TYPE,
  key,val
})

export const storeAnnouncePage = (val) => ({
  type: STORE_ANNOUNCE_PAGE,
  val
})
export const setDraftPage = (res) => ({
  type: SET_DRAFT_PAGE,
  res
})
export const setSchedulePage = (res) => ({
  type: SET_SCHEDULE_PAGE,
  res
})

export const switchAnnounceData =(res) =>({
  type:SWITCH_ANNOUNCEMENT_DATA,
  res
})

export const setAnnounceKey =(val)=>({
  type:SET_ANNOUNCE_KEY,
  val
})

export const setStdFilterValue = (val) => ({
  type: SET_STD_FILTER_VALUE,
  val
})