import { deleteAnnouncement, fetchAnnouncement, fetchNotification, postAnnouncemnt, setAnnounceKey, storeAnnouncePage } from 'actions/announcement';
import { Input, Modal, Typography, Select, Button, Checkbox, TimePicker, DatePicker, Alert, Tag } from 'antd'
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import styled from 'styled-components'
import 'react-quill/dist/quill.snow.css';
import { CalendarOutlined, DeleteFilled, DeleteOutlined, FieldTimeOutlined, LockFilled, LockOutlined, PercentageOutlined, WarningTwoTone } from '@ant-design/icons';
import { notificationBadgeList } from 'actions/login';
import CreateAnnouncmentModal from './createAnnouncmentModal';
import Dragger from 'antd/lib/upload/Dragger';
import { message, Upload } from 'antd';
import file_image_1 from '../../../Assets/images/file_image_1.svg'
import ic_file_eadd from '../../../Assets/images/ic_file_eadd.svg'
import Notification from "services/Notification";


const { Text } = Typography
const { Option } = Select

const calenderIcon = <CalendarOutlined style={{ color: "#1089FF" }} />
const timeIcon = <FieldTimeOutlined style={{ color: "#1089FF" }} />
const CreateFormAnnouncementMain = styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
`;

const CreateFormAnnouncementScroll = styled.div`
        width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
`;

const CreateFormAnnouncementInput = styled.div`
        width: 100%;
        height: 85%;
        padding: 10px;
        overflow-y: scroll;
`;

const CreateFormAnnouncementButton = styled.div`
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding:20px
`;

const EditFormScheduleButton = styled.div`
        width:100%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center:
        padding:20px;
`;

const EditFormDraftButton = styled.div`
        width:100%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        padding:10px;
`;

const CloneAnnouncement = ({
    isCloneModal, setIsCloneModal, edit, postAnnouncemnt, editData, fetchAnnouncement, togglePageLoader, deleteAnnouncement, fetchNotification, notificationBadgeList, SetKey, standardList,
    sortFilter, searchFilter, announcement, key, setIsModalVisible, setAnnounceKey, storeAnnouncePage
}) => {

    console.log("AA-edit", editData);
    const [check, setCheck] = useState(false)
    const [announceButtonLoader, setAnnounceButtonLoader] = useState(false)
    const [draftButtonLoader, setDraftButtonLoader] = useState(false)
    const [scheduleLoader, setScheduleLoader] = useState(false)
    const [deleteButtonLoader, setDeleteButtonLoader] = useState(false)
    const [id, setId] = useState(null)

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [target, setTarget] = useState('')
    const [date, setDate] = useState('')
    const [time, setTime] = useState('')
    const [type, setType] = useState('')
    const [std, setStd] = useState([])
    const [classListShow, setClassListShow] = useState(true)
    const [className, setClassName] = useState('')
    const [descriptionLength, setDescriptionLength] = useState(null)
    const [file, setfile] = useState([])
    const [hideFile, setHideFile] = useState(true)
    const [fileVisible, setFileVisible] = useState(editData?.fileName)
    const [isDisable, setIsDisable] = useState(false)
    const [isFileSize, setIsFileSize] = useState(10 * 1024 * 1024)
    const [deleteShow, setDeleteShow] = useState(false)



    useEffect(() => {
        // setIsModalVisible(false)

        // if (edit == 'clone' && Object.keys(editData)?.length != 0) {
        setTitle(editData?.title)
        setDescription(editData?.description)
        setTarget(editData?.target)
        console.log("AA-time", time);
        // setDate(moment(editData?.date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
        // setTime(moment(time, 'HH:mm').format('HH:mm'))
        setId(editData?.id)
        setCheck(false)
        console.log("AA-abcd", editData, "edita", editData?.standardStatus);
        if (editData?.target == 'Student' && editData?.standardStatus == 0) {
            if (editData?.standardAnnouncement?.length != 0) {
                let temp = []
                editData?.standardAnnouncement?.map((item) => (
                    temp?.push(item?.standardId)
                ))
                console.log("AAA-std", std);
                setStd(temp)
                setClassListShow(true)
            }
        }
        else if (editData?.target == 'Student' && editData?.standardStatus == -1) {
            setStd([-1])
            setClassListShow(false)
        }
        // }
    }, [JSON.stringify(editData)])

    console.log("AA-std1", std);

    useEffect(() => {

        if (std[0] != undefined || std[0] != -1) {
            console.log("AAA-std1", std?.length, standardList.length);
            if (std?.length == standardList.length) {
                setClassListShow(false)
                setStd([-1])
            }
        }

    }, [std?.length])
    let quillReff = null
    var details = {}
    function handleRemove(info) {
        let temp = []
        for (let i = 0; i < file.length; i++) {
            if (file[i].uid !== info) {
                temp.push(file[i])
            }
        }
        setfile([...temp]);
        if (hideFile) {
            setFileVisible('old file')
        } else {
            setFileVisible(null);
        }
        // setFileVisible(null)

    }
    const fileSizeLimit = 10 * 1024 * 1024

    const props = {
        name: 'file',
        multiple: true,
        action: "https://testprod.aulas.in/api/mock-api/mock-upload",
        onChange(info) {
            const { status } = info.file;
            console.log("AAstatus", status);
            console.log("hello", info.file.originFileObj);
            if (info.file.status === 'uploading') {
                return

            }
            if (info.file.status === "done" || info.file.status === "error") {
                getBase64(info.file.originFileObj, (imageUrl) => {
                    if (info.file.size > fileSizeLimit) {
                        // setIsFileSize([...file, info.file.originFileObj])
                        message.error('File size exceeds the limit of 10MB.');
                        if (hideFile) {
                            setFileVisible('new file and old file')
                        }
                    }
                    else {
                        message.success(`${info.file.name} file uploaded successfully.`);
                        setfile([...file, info.file.originFileObj])
                        if (hideFile) {
                            setFileVisible('new file and old file')
                        } else {
                            setFileVisible('old file')
                        }

                    }
                    // setFileVisible('file is there')
                    // toggleImageLoader(false);
                });



            }
        },
        onDrop(e) {
            console.log('AAA-Dropped files', e.dataTransfer.files);
        },
        onRemove(info, index) {
            handleRemove(info.uid);
        }
    };
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
        ],
    }

    const handleCancle = () => {
        setIsModalVisible(false)
        setTitle(editData?.title)
        setDescription(editData?.description)
        setTarget(editData?.target)
        setDate(moment(editData?.date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
        setTime(moment(time, 'HH:mm').format('HH:mm'))
        setId(editData?.id)
        setCheck(false)
        // setfile(editData?.fileName[0]?.key)
        if (editData?.target == 'Student' && editData?.standardStatus == 0) {
            if (editData?.standardAnnouncement?.length != 0) {
                let temp = []
                editData?.standardAnnouncement?.map((item) => (
                    temp?.push(item?.standardId)
                ))
                console.log("AAA-std", std);
                setStd(temp)
                setClassListShow(true)
            }
        }
        else if (editData?.target == 'Student' && editData?.standardStatus == -1) {
            setStd([-1])
            setClassListShow(false)
        }
        setIsCloneModal(false)
    }
    const handleChangeTarget = (value) => {
        if (value) {
            setTarget(value)
        }
        else {
            setTarget(null)
        }
        setStd([])
    }

    const onChangeCheckBox = (e) => {
        setCheck(e.target.checked)
    }

    const handleTitle = (e) => {
        e.preventDefault()
        setTitle(e.target.value)
    }

    const handleDescription = (content, delta, source, editor) => {
        // source == user means while creating or change by user
        if (source == 'user') {
            details = {
                mobile: quillReff.getEditorContents(),
                web: quillReff.editor.getContents()
            }
            setDescription(JSON.stringify(details))
        }
    }

    const handleDescriptionKeyPress = (event) => {
        if (quillReff.getEditorContents() != '<p><br></p>' && quillReff.editor.getText().trim() != '') {
            setDescriptionLength(quillReff.editor.getLength())
            details = {
                mobile: quillReff.getEditorContents(),
                web: quillReff.editor.getContents()
            }
            setDescription(JSON.stringify(details))

        }
        else {
            setDescription('')
        }
    }
    const handleDescriptionkeyUp = (e) => {
        if (quillReff.getEditorContents() != '<p><br></p>' && quillReff.editor.getText().trim() != '') {

            setDescriptionLength(quillReff.editor.getLength())
            details = {
                mobile: quillReff.getEditorContents(),
                web: quillReff.editor.getContents()
            }
            setDescription(JSON.stringify(details))

        }
        else {
            setDescription('')
        }
    }



    const handleButton = (type) => {
        // setIsModalVisible(false)

        setType(type)
        let object = {}
        if (edit == 'announce') {

            if (title && target && type && description !== null) {

                object = {
                    cloneId: id,
                    title: title,
                    description: description,
                    target: target,
                    standardIds: std,
                    type: type,
                    date: date ? date : moment().format('YYYY-MM-DD'),
                    time: time != 'Invalid date' ? time : moment().format('HH:mm'),
                    typeAnnouncement: "text",

                }

            } else {
                object = {
                    cloneId: id,
                    title: title,
                    // description: description,
                    target: target,
                    standardIds: std,
                    type: type,
                    date: date ? date : moment().format('YYYY-MM-DD'),
                    time: time ? time : moment().format('HH:mm'),

                    typeAnnouncement: "file",
                    files: [...file],

                }
            }
        }
        if (edit == 'clone') {
            console.log("AA-object4", date, date ? date : moment().format('YYYY-MM-DD'));
            console.log("AA-object1", time);

            if (title && target && type && description !== null) {

                object = {
                    cloneId: id,
                    title: title,
                    description: description,
                    target: target,
                    standardIds: std,
                    type: type,
                    date: date ? date : moment().format('YYYY-MM-DD'),
                    time: time ? time : moment().format('HH:mm'),
                    typeAnnouncement: "text",

                }

            } else {
                object = {
                    cloneId: id,
                    title: title,
                    // description: description,
                    target: target,
                    standardIds: std,
                    type: type,
                    date: date ? date : moment().format('YYYY-MM-DD'),
                    time: time ? time : moment().format('HH:mm'),

                    typeAnnouncement: "file",
                    files: [...file],

                }
            }
        }
        if (edit == 'schedule') {
            console.log("AA-dateClone123", date);

            if (title && target && type && description !== null) {

                object = {
                    cloneId: id,
                    title: title,
                    description: description,
                    target: target,
                    standardIds: std,
                    type: type,
                    date: date ? date : moment().format('YYYY-MM-DD'),
                    time: time ? time : moment().format('HH:mm'),
                    typeAnnouncement: "text",

                }

            } else {
                object = {
                    cloneId: id,
                    title: title,
                    // description: description,
                    target: target,
                    standardIds: std,
                    type: type,
                    date: date ? date : moment().format('YYYY-MM-DD'),
                    time: time ? time : moment().format('HH:mm'),
                    typeAnnouncement: "file",
                    files: [...file],

                }
            }
        }

        if (type == 'ANNOUNCENOW') {
            setAnnounceButtonLoader(true)
            // SetKey('3')
            postAnnouncemnt(object, () => { setAnnounceButtonLoader(false) },
                () => {
                    setAnnounceKey('3')

                    setIsModalVisible(false)
                    setAnnounceButtonLoader(false)
                    setDraftButtonLoader(false)
                    setScheduleLoader(false)
                    setDeleteButtonLoader(false)
                    // setDescriptionLength(null)
                    setTitle('')
                    // setDescription('')
                    setTarget('')
                    setDate('')
                    setTime('')
                    setStd([])
                    setCheck(false)
                    setIsCloneModal(false)
                    notificationBadgeList()
                    togglePageLoader(true)
                    fetchAnnouncement('', '', () => togglePageLoader(false))
                })
        }
        if (type == 'DRAFT') {
            console.log("AA-object4", object);

            setDraftButtonLoader(true)
            // SetKey('1')
            postAnnouncemnt(object, () => { setDraftButtonLoader(false) },
                () => {
                    setAnnounceKey('1')

                    setIsModalVisible(false)
                    setAnnounceButtonLoader(false)
                    setDraftButtonLoader(false)
                    setScheduleLoader(false)
                    setDeleteButtonLoader(false)
                    // setDescriptionLength(null)
                    setTitle('')
                    // setDescription('')
                    setTarget('')
                    setDate('')
                    setTime('')
                    setStd([])
                    setCheck(false)
                    setIsCloneModal(false)
                    notificationBadgeList()
                    togglePageLoader(true)
                    fetchAnnouncement('', '', () => togglePageLoader(false))
                }
            )
        }
        if (type == 'SCHEDULE') {
            setScheduleLoader(true)
            postAnnouncemnt(object, () => { setScheduleLoader(false) },
                () => {
                    setAnnounceKey('2')

                    setIsModalVisible(false)
                    setAnnounceButtonLoader(false)
                    setDraftButtonLoader(false)
                    setScheduleLoader(false)
                    setDeleteButtonLoader(false)
                    // setDescriptionLength(null)
                    setTitle('')
                    // setDescription('')
                    setTarget('')
                    setDate('')
                    setTime('')
                    setStd([])
                    setCheck(false)
                    setIsCloneModal(false)
                    notificationBadgeList()
                    togglePageLoader(true)
                    fetchAnnouncement('', '', () => togglePageLoader(false))

                })
        }

    }
    // const successCallback = () => {
    //     console.log("AAA123-logs",type);
    //     setIsModalVisible(false)
    //     setAnnounceButtonLoader(false)
    //     setDraftButtonLoader(false)
    //     setScheduleLoader(false)
    //     setDeleteButtonLoader(false)

    //     setTitle('')

    //     setTarget('')
    //     setDate('')
    //     setTime('')
    //     setStd([])
    //     setCheck(false)
    //     setIsCloneModal(false)
    //     notificationBadgeList()
    //     togglePageLoader(true)
    //     fetchAnnouncement('', '', () => togglePageLoader(false))
    // }
    const handleClass = (e, obj) => {
        if (e.includes(-1)) {
            setClassListShow(false)
            setStd([-1])
        }
        else if (!e.length == 0) {
            setStd(e)
        }
        else {
            setStd(e)
            setClassListShow(true)
        }
    }

    const onSelect = (value, obj) => {
        if (value == -1) {
            setStd([-1])
        }
    }
    const handleDate = (date, dateString) => {
        setDate(dateString)
        setIsDisable(true)
    }
    const handleTime = (time, timeString) => {
        setTime(timeString)
    }
    // const handleDelete = () => {

    //     console.log("AA-id1",id);
    //     setDeleteButtonLoader(true)
    //     deleteAnnouncement(id, () => setDeleteButtonLoader(false), successCallback)
    // }

    const handleRemoveValue = () => {
        if (file.length != 0) {
            setFileVisible('new file')
        } else {
            setFileVisible(null);
        }
        // setFileVisible(null)
        setHideFile(false)
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');

    }
    const disabledStartTimeHours = () => {
        const hours = [];
        console.log("AA-datew", date);
        if (moment().format('YYYY-MM-DD') === date) {
            for (var i = 0; i < moment().hour(); i++) {
                hours.push(i)
            }

        }
        return hours;
    };

    const disabledStartTimeMinutes = (selectedHour) => {
        var minutes = []
        if (selectedHour === moment().hour() && moment().format('YYYY-MM-DD') === date) {
            for (var i = 0; i <= (moment().minute() + 1); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    console.log("AA-isFileSize", isFileSize[0]?.size, fileSizeLimit, isFileSize[0]?.size != fileSizeLimit ? true : false);

    return (
        <div>
            <Modal
                // className="createFormAnnouncement"
                className="modal-round-corner"
                open={isCloneModal}
                footer={null}
                //centered={true}
                width={"60%"}
                closable={() => { setIsCloneModal(false) }}
                bodyStyle={{ height: "100%" }}
                onCancel={() => handleCancle()}
            >
                <CreateFormAnnouncementMain>
                    <CreateFormAnnouncementScroll>
                        <Text style={{ fontWeight: 'bold', fontSize: '22px', color: '#191919' }}>{"Clone Announcement"} </Text>
                        <CreateFormAnnouncementInput>
                            <div style={{ padding: '10px' }}>
                                <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Title</Text>
                                {console.log("AA-editData?.title", editData?.title)}
                                <Input style={{ borderRadius: '5px', padding: '5px' }} placeholder='Enter title' value={title ? title : ''} onChange={(e) => handleTitle(e)} />
                            </div>
                            {
                                editData?.typeAnnouncement === 'file' ?
                                    <>
                                        <div style={{ marginTop: "20px", }}>
                                            <div>Attachment(s)</div>
                                            <Dragger {...props}
                                                // disabled={fileVisible}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xsl,.pdf,.doc,.docx,.jpg,.jpeg,.png" listType="picture" progress={PercentageOutlined} style={{ borderColor: "#1089FF" }}>
                                                <div className="ant-upload-drag-icon">
                                                    <img src={ic_file_eadd} />
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <div className="ant-upload-text" style={{ color: "#AEAEAE", fontSize: "13px", fontWeight: "bold" }}>Drop file here or</div>
                                                    <div className="ant-upload-hint" style={{ color: "#1089FF", fontSize: "13px", fontWeight: "bold", marginLeft: "5px" }}>
                                                        Click to Upload
                                                    </div>
                                                </div>
                                            </Dragger>
                                        </div>
                                        {fileVisible == "new file and old file" ? Notification.error("Error", 'Multiple files are not allowed') : null}

                                        {hideFile ? <div style={{ marginTop: '10px', width: "100%" }}>
                                            <div className='shadow-box hover-shadow customCardShadow' style={{ height: "60px", display: "flex" }}>
                                                <div>
                                                    <img src={file_image_1} style={{ width: "60%", marginLeft: "10px", marginTop: "13px" }} />
                                                </div>
                                                <div>
                                                    <div style={{ marginTop: "22px", fontSize: "13px", marginLeft: "10px", fontWeight: "bold" }}>{editData?.fileName[0]?.key}</div>
                                                    <div style={{ borderColor: "#E6E6E6", color: "#AEAEAE", fontSize: "11px", fontWeight: "bold", cursor: "point", cursor: "pointer", marginLeft: "600px", marginTop: "-25px" }} onClick={() => { handleRemoveValue() }}><DeleteOutlined style={{ fontSize: "20px", color: "#FF414D" }} /></div>
                                                </div>

                                            </div>
                                        </div> : null}
                                    </> :


                                    <div className='descriptionClass test-texteditor-wrapper' style={{ padding: '10px' }}>
                                        <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Description</Text>
                                        <ReactQuill
                                            className='annocnementDescription link-div'
                                            theme='snow'
                                            style={{ height: '100%' }}
                                            modules={modules}
                                            value={description && Object.keys(description).length != 0 ? JSON.parse(description).mobile : ''}
                                            onChange={(content, delta, source, editor) => handleDescription(content, delta, source, editor)}
                                            ref={el => quillReff = el}
                                            placeholder="Enter Description"
                                            bounds={'.annocnementDescription'}
                                            //  onBlur={() => handleBlur()}
                                            // onKeyPress={(e) => handleDescriptionKeyPress(e)}
                                            onKeyUp={(e) => handleDescriptionkeyUp(e)}

                                        />
                                        {descriptionLength != null && descriptionLength > 250 ?
                                            <div>
                                                <WarningTwoTone twoToneColor='#FF0000' /> <Text style={{ color: 'red' }}>Description should be less than 250 character</Text>
                                            </div>
                                            : null}
                                    </div>
                            }
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ width: '50%', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Target</Text>
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        value={target}
                                        onChange={handleChangeTarget}
                                        size='middle'
                                        placeholder={'Select Target'}
                                    >
                                        <Option value={'All'}>All</Option>
                                        <Option value={'Teacher'}>Teacher</Option>
                                        <Option value={'Student'}>Student</Option>
                                        <Option value={'Data Operator'}>Data Operator</Option>
                                        <Option value={'Operation'}>Operation</Option>
                                    </Select>
                                </div>
                                {target == 'Student' ?
                                    <div id='select' style={{ width: '50%', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                                        <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Class(es)</Text>
                                        {console.log("AA-studentClass", std)}
                                        <Select
                                            getPopupContainer={(trigger) => trigger.parentElement}
                                            placeholder='Select Class'
                                            mode='multiple'
                                            // tagRender={tagRender}
                                            filterOption={true}
                                            optionFilterProp='children'
                                            onChange={handleClass}
                                            onSelect={onSelect}
                                            value={std}

                                        >
                                            <Option value={-1}>All</Option>
                                            {classListShow && standardList.length != 0 ? standardList.map((item) => (
                                                <Option value={item.id}>{item.std + "-" + item.section}</Option>
                                            )) : null}
                                        </Select>
                                    </div>
                                    : null}
                            </div>
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Checkbox style={{ padding: '5px' }} checked={check} onChange={(e) => { onChangeCheckBox(e) }}>Schedule for Later <LockFilled /></Checkbox>
                                </div>
                                {true ?
                                    <div className='m-t-5' style={{ padding: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Date</Text>
                                            {console.log("AA-date,time", editData?.date)}
                                            <DatePicker

                                                inputReadOnly={true}
                                                size='middle'
                                                disabled={check === false}
                                                onChange={handleDate}
                                                //  format={'DD-MM-YYYY'} 
                                                defaultValue={editData?.date ? moment(editData?.date, 'YYYY-MM-DD') : ''}
                                                disabledDate={disabledDate}
                                                suffixIcon={calenderIcon}

                                            > </DatePicker>
                                        </div>
                                        <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Time</Text>
                                            <TimePicker size='middle'
                                                inputReadOnly={true}
                                                disabled={check === false}

                                                onChange={handleTime} format={'HH:mm'}
                                                defaultValue={editData?.time ? moment(editData?.time, 'HH:mm') : ''}
                                                disabledHours={disabledStartTimeHours}
                                                disabledMinutes={disabledStartTimeMinutes}
                                                suffixIcon={timeIcon}


                                            ></TimePicker>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                            {/* <div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Checkbox  disabled={true} style={{ padding: '5px' }} checked={check} onChange={(e)=>{onChangeCheckBox(e)}}>Schedule for Later {" "}{" "}{" "}<LockOutlined /></Checkbox>
                                </div>
                                {true ?
                                    <div className='m-t-5' style={{ padding: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Date</Text>
                                            {console.log("AA-date,time",editData?.date)}
                                            <DatePicker
                                            disabled={true}
                                             
                                             inputReadOnly={true}
                                             size='middle' 
                                            //  disabled={check === false} 
                                             onChange={handleDate}
                                            //  format={'DD-MM-YYYY'} 
                                             defaultValue={editData?.date ? moment(editData?.date,'YYYY-MM-DD') : ''}
                                             disabledDate={disabledDate}
                                             suffixIcon={calenderIcon}
                                              
                                              ></DatePicker>
                                        </div>
                                        <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Time</Text>
                                            <TimePicker size='middle' 
                                            inputReadOnly={true}
                                            // disabled={!isDisable || check === false}
                                            disabled={true}

                                            onChange={handleTime} format={'HH:mm'} 
                                            defaultValue={editData?.time ? moment(editData?.time,'HH:mm') : ''} 
                                            disabledHours={disabledStartTimeHours}
                                            disabledMinutes={disabledStartTimeMinutes}
                                            suffixIcon={timeIcon}

                                            
                                            ></TimePicker>
                                        </div>
                                    </div>
                                    : null}
                            </div> */}
                        </CreateFormAnnouncementInput>
                    </CreateFormAnnouncementScroll>


                    <div style={{ width: '100%', }}>
                        {
                            edit === 'schedule' ?
                                <EditFormScheduleButton style={{ justifyContent: "center" }}>
                                    <div style={{ width: '70%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {console.log("AA-std", std)}
                                        <Button loading={draftButtonLoader} type='default' style={{ width: '45%', borderRadius: '16px', marginRight: '10px', textAlign: 'center' }} onClick={() => handleButton('DRAFT', file)} disabled={(fileVisible && fileVisible != 'new file and old file') || ((target == 'Student' && std.length != 0 && std != [] && title && title.trim() != '' && description != '' && descriptionLength <= 250) || ((title && title.trim() != '') && description != '' && target && target != 'Student' && descriptionLength <= 250)) && (editData?.typeAnnouncement == 'text') ? (draftButtonLoader || deleteButtonLoader) ? true : false : true}>Save As Draft</Button>
                                        {check !== true ?
                                            <Button loading={announceButtonLoader} type='primary' style={{ width: '50%', borderRadius: '16px', textAlign: 'center' }} onClick={() => handleButton('ANNOUNCENOW')} disabled={(fileVisible && fileVisible != 'new file and old file') || ((target == 'Student' && std.length != 0 && title && title.trim() != '' && description != '' && descriptionLength <= 250) || ((title && title.trim() != '') && description != '' && target && target != 'Student' && descriptionLength <= 250)) && (editData?.typeAnnouncement == 'text') ? (announceButtonLoader || deleteButtonLoader) ? true : false : true}>Announce Now</Button>
                                            :
                                            <Button loading={scheduleLoader} type='primary' style={{ width: '180px', marginLeft: "8px", borderRadius: '16px', textAlign: 'center' }} onClick={() => handleButton('SCHEDULE')}
                                                // disabled={((target == 'Student' && std.length != 0 && title && title.trim() != '' && date != '' && time != '') && description != '' && descriptionLength <= 250) || ((title && title.trim() != '') && description != '' && target && target != 'Student' && descriptionLength <= 250 && date != '' && time != '') ? scheduleLoader ? true : false : true}

                                            // disabled={(fileVisible && fileVisible != 'new file and old file') ||(date != '' && time != '') ||(( target == 'Student' && std.length != 0 && title && title.trim() != '' && description != '' && descriptionLength <= 250 && date != '' && time != '') || ((title && title.trim() != '') && description != '' && target && target != 'Student' && descriptionLength <= 250)) && (editData?.typeAnnouncement == 'text')  ? (scheduleLoader || deleteButtonLoader) ? true : false : true}
                                            // disabled={date == '' ? false : true}
                                            >Schedule</Button>
                                        }
                                    </div>
                                    {/* <Button type='default' style={{ width: '150px', marginRight: "8px", borderRadius: '16px', textAlign: 'center' }} onClick={() => setDeleteShow(true)}>Delete</Button> */}
                                    {/* <Button type='primary' style={{ width: '150px', marginLeft: "8px", borderRadius: '16px', textAlign: 'center' }} onClick={() => handleButton('SCHEDULE')}
                                        disabled={(fileVisible && fileVisible != 'new file and old file') ||(( target == 'Student' && std.length != 0 &&  description != '' && descriptionLength <= 250) || ((title && title.trim() != '') && description != '' && target && target != 'Student' && descriptionLength <= 250)) && (editData?.typeAnnouncement == 'text') ? (scheduleLoader || deleteButtonLoader) ? true : false : true}
                                        >Save</Button> */}
                                </EditFormScheduleButton>
                                :
                                <EditFormDraftButton style={{ justifyContent: "center" }}>
                                    {/* <div style={{ width: '20%' }}>
                                            <Button
                                                disabled={draftButtonLoader || announceButtonLoader}
                                                loading={deleteButtonLoader} type='primary' style={{ width: '100%', borderRadius: '16px', textAlign: 'center' }} onClick={() => setDeleteShow(true)}>Delete</Button>
                                        </div> */}
                                    <div style={{ width: '70%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {console.log("AA-std", std)}
                                        <Button loading={draftButtonLoader} type='default' style={{ width: '45%', borderRadius: '16px', marginRight: '10px', textAlign: 'center' }} onClick={() => handleButton('DRAFT', file)} disabled={(fileVisible && fileVisible != 'new file and old file') || ((target == 'Student' && std.length != 0 && std != [] && title && title.trim() != '' && description != '' && descriptionLength <= 250) || ((title && title.trim() != '') && description != '' && target && target != 'Student' && descriptionLength <= 250)) && (editData?.typeAnnouncement == 'text') ? (draftButtonLoader || deleteButtonLoader) ? true : false : true}>Save As Draft</Button>
                                        {check !== true ?
                                            <Button loading={announceButtonLoader} type='primary' style={{ width: '50%', borderRadius: '16px', textAlign: 'center' }} onClick={() => handleButton('ANNOUNCENOW')} disabled={(fileVisible && fileVisible != 'new file and old file') || ((target == 'Student' && std.length != 0 && title && title.trim() != '' && description != '' && descriptionLength <= 250) || ((title && title.trim() != '') && description != '' && target && target != 'Student' && descriptionLength <= 250)) && (editData?.typeAnnouncement == 'text') ? (announceButtonLoader || deleteButtonLoader) ? true : false : true}>Announce Now</Button>
                                            :
                                            <Button loading={scheduleLoader} type='primary' style={{ width: '180px', marginLeft: "8px", borderRadius: '16px', textAlign: 'center' }} onClick={() => handleButton('SCHEDULE')}
                                                disabled={((target == 'Student' && std.length != 0 && title && title.trim() != '' && date != '' && time != '') && description != '' && descriptionLength <= 250) || ((title && title.trim() != '') && description != '' && target && target != 'Student' && descriptionLength <= 250 && date != '' && time != '') ? scheduleLoader ? true : false : true}

                                            // disabled={(fileVisible && fileVisible != 'new file and old file') ||(date != '' && time != '') ||(( target == 'Student' && std.length != 0 && title && title.trim() != '' && description != '' && descriptionLength <= 250 && date != '' && time != '') || ((title && title.trim() != '') && description != '' && target && target != 'Student' && descriptionLength <= 250)) && (editData?.typeAnnouncement == 'text')  ? (scheduleLoader || deleteButtonLoader) ? true : false : true}
                                            // disabled={date == '' ? false : true}
                                            >Schedule</Button>
                                        }
                                    </div>
                                    {console.log("AA-bcd", date)}
                                </EditFormDraftButton>

                        }
                        {/* {deleteShow ?
                            <div style={{textAlign:"center",marginTop:"20px"}}>
                            <div style={{fontSize:"18px",fontWeight:"bold"}}>Confirm</div>
                                        <div style={{fontSize:"15px",color:"#636363"}}>Are you sure you want to delete ?</div>
                                        <div style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                                        <div>
                                            <Button
                                              
                                                loading={deleteButtonLoader} type='primary' style={{ fontWeight: "bold", width: '100%', borderRadius: '16px', textAlign: 'center' }} onClick={() => handleDelete()}>YES</Button>

                                        </div>
                                        <div style={{marginLeft:"20px"}}>
                                            <Button type='primary' style={{ fontWeight: "bold", width: '100%', borderRadius: '16px', textAlign: 'center' }} onClick={()=>{setDeleteShow}}>NO</Button>
                                        </div>

                                        </div>

                            </div>:null} */}
                    </div>
                </CreateFormAnnouncementMain>

            </Modal>
        </div>
    )
}


const mapStateToProps = (state) => {
    const {
        standardList,
        sortFilter,
        searchFilter, announcement,

    } = state.announcement;
    return {
        standardList,
        sortFilter,
        searchFilter, announcement,
    };
};

const mapDispatchToProps = (dispatch) => ({
    postAnnouncemnt: (object, callback, successCallback) => dispatch(postAnnouncemnt(object, callback, successCallback)),
    fetchAnnouncement: (search, sort, key, callback) => dispatch(fetchAnnouncement(search, sort, key, callback)),
    deleteAnnouncement: (id, callback, successCallback) => dispatch(deleteAnnouncement(id, callback, successCallback)),
    fetchNotification: () => dispatch(fetchNotification()),
    notificationBadgeList: () => dispatch(notificationBadgeList()),
    setAnnounceKey: (val) => dispatch(setAnnounceKey(val)),


});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(CloneAnnouncement);