import React, { useState } from 'react'
import styled from 'styled-components'
import { Dropdown, Menu, Popover, Typography } from 'antd';
import CreateAnnouncement from './modal/createAnnouncement';
import HtmlParser from 'react-html-parser';
import ic_file_add from "../../Assets/images/ic_file_add.svg"
import CloneAnnouncement from './modal/cloneModal'


import moment from 'moment';
import { CopyOutlined, MoreOutlined } from '@ant-design/icons';

const { Text } = Typography
const AnnounceSchedule = styled.div`
    width:330px;
    height:90px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    box-shadow: 1px 1px 6px #302A5F14;
    margin: 10px 15px 15px 10px;
    &:hover {
        border: none; 
        box-shadow: 2px 3px 8px #c7cece;
    }s

`;
const AnnounceScheduleMain = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-evenly

`;

const DateAndTime = styled.div`
    width:20%;
    height:70px;
    background:#F6F4FE;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    border-radius: 8px;
`;

const TitleAndDescription = styled.div`
    width:65%;
    height:70px;
    display:flex;
    flex-direction:column;
    align-items:stretch;
    justify-content:center;
`;

const AnnounceScheduleCard = ({ scheduleItem,togglePageLoader,SetKey ,setIsSearch,setSort}) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const [isCloneModal,setIsCloneModal] =useState(false)
    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }

        return false;
    }
    const [isModalVisible, setIsModalVisible] = useState(false);
    const htmlText = scheduleItem?.description ? JSON.parse(scheduleItem.description)?.mobile : ''
    const menu = (
        <div onClick={(e) => { e.stopPropagation() }}>
          <Menu
           style={{ width: "100%", }}>
            <Menu.Item style={{ backgroundColor: 'white' }} onClick={() => setIsCloneModal(true)}>
              <CopyOutlined />
              Clone
            </Menu.Item>
          </Menu>
        </div>
      );
    return (
        <div>
            {scheduleItem?.typeAnnouncement !== 'file' ?
                <AnnounceSchedule className="cursor-pointer" onClick={() => {setIsModalVisible(true)}}>
                    <AnnounceScheduleMain>
                        <DateAndTime>
                            <Text style={{ fontSize: "11px", color: "#594099", fontWeight: 'bold' }}>{`${moment(scheduleItem?.date, 'YYYY-MM-DD').format('MMM Do')}`}</Text>
                            <Text style={{ fontSize: "11px", color: "#594099", fontWeight: 'bold' }}>{`${moment(scheduleItem?.time, 'HH:mm:ss').format('hh:mm a')}`}</Text>
                        </DateAndTime>
                        <TitleAndDescription>
                            <div style={{display:"flex", justifyContent: 'space-between', width: '100%'}}>
                            <Text style={{ fontFamily: 'roboto', color: '#191919', fontSize: '16px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{scheduleItem?.title}</Text>
                            <div>
                            <Dropdown placement="bottomRight" overlay={menu}>
                                            <MoreOutlined style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation() }} />
                                        </Dropdown>

                                    </div>

                            </div>
                            
                            <Typography.Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: '14px', height: "25px", width: 250 }} ellipsis={{tooltip:true}}>
                            {!IsJsonString(JSON.parse(scheduleItem?.description)?.mobile) && isHTML(JSON.parse(scheduleItem?.description)?.mobile) ? HtmlParser(JSON.parse(scheduleItem?.description)?.mobile) : HtmlParser(JSON.parse(scheduleItem?.description)?.mobile)}
                            </Typography.Text>

                        </TitleAndDescription>
                    </AnnounceScheduleMain>
                </AnnounceSchedule> :
                <AnnounceSchedule className="cursor-pointer" onClick={() => setIsModalVisible(true)}>
                    <AnnounceScheduleMain>
                        <DateAndTime>
                            <Text style={{ fontSize: "11px", color: "#594099", fontWeight: 'bold' }}>{`${moment(scheduleItem?.date, 'YYYY-MM-DD').format('MMM Do')}`}</Text>
                            <Text style={{ fontSize: "11px", color: "#594099", fontWeight: 'bold' }}>{`${moment(scheduleItem?.time, 'HH:mm:ss').format('hh:mm a')}`}</Text>
                        </DateAndTime>
                        <TitleAndDescription>
                        <div style={{display:"flex", justifyContent: 'space-between', width: '100%'}}>
                            <Text style={{ fontFamily: 'roboto', color: '#191919', fontSize: '16px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{scheduleItem?.title}</Text>
                            <div>
                            <Dropdown placement="bottomRight" overlay={menu}>
                                            <MoreOutlined style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation() }} />
                                        </Dropdown>

                                    </div>

                            </div>
                            
                            <Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{""}</Text>
                            <div className='' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {scheduleItem?.fileName?.map((i) => (
                                    <div style={{ fontSize: "14px" }}><img src={ic_file_add} />{i.key}</div>

                                ))}</div>
                        </TitleAndDescription>
                    </AnnounceScheduleMain>
                </AnnounceSchedule>}
            {(isModalVisible && <CreateAnnouncement setIsSearch={setIsSearch} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} edit='schedule' editData={scheduleItem} togglePageLoader={togglePageLoader} SetKey={SetKey} setSort={setSort}/>)}
           {(isCloneModal && <CloneAnnouncement isCloneModal={isCloneModal} setIsCloneModal={setIsCloneModal} setIsModalVisible={setIsModalVisible}  edit='schedule' editData={scheduleItem} togglePageLoader={togglePageLoader} SetKey={SetKey} setSort={setSort} />)}

        </div>
    )
}

export default AnnounceScheduleCard