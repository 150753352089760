import { Input, Modal, Typography, Select, Button, Checkbox, TimePicker, DatePicker } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import HtmlParser from 'react-html-parser';
import ReactQuill from 'react-quill';
import styled from 'styled-components'
import ic_file_add from "../../../Assets/images/ic_file_add.svg"
import file_image_1 from "../../../Assets/images/file_image_1.svg"
import fileDownload from "js-file-download";
import { Api } from 'services';
import Axios from 'axios';
import { DownloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { deleteAnnouncement, fetchAnnouncement, storeAnnouncePage } from 'actions/announcement';
import CloneModal from './cloneModal';


const { Text } = Typography

const ViewAnnounceMain = styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
`;


const ViewAnnounceDetails = styled.div`
        width: 100%;
        height: 80%;
        padding: 30px;
        border-radius: 8px;
        margin-top:40px;
`;


const ViewAnnounceTargetAndTime = styled.div`
        width:85%;
        margin-top:20px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        margin-bottom:20px;

`;


const ViewAnnouncement = ({ isModalVisible, setIsModalVisible, announcedData, file, deleteAnnouncement, fetchAnnouncement, togglePageLoader, SetKey, setIsSearch, setSort, search, sort, storeAnnouncePage, setSearch }) => {
    const [deleteButtonLoader, setDeleteButtonLoader] = useState(false)
    const [isCloneModal, setIsCloneModal] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)

    const handleCancle = () => {
        setIsModalVisible(false)
    }

    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }

        return false;
    }

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    console.log("AA-fileName1", announcedData);
    const downloadFile = (file) => {


        let fileName = file.key
        Axios.get(Api._s3_url + file.data, {
            responseType: "blob",
        }).then((res) => {

            fileDownload(res.data, fileName);
        });
    }

    const handleDelete = () => {
        setSort('')
        setIsSearch('')
        // setSearch('')
        setDeleteButtonLoader(true)
        deleteAnnouncement(announcedData?.id, () => setDeleteButtonLoader(false), () => { setIsModalVisible(false) })
        togglePageLoader(true)
        fetchAnnouncement('', '', () => {
            togglePageLoader(false)

        })
        storeAnnouncePage(1)

    }
    const htmlText = announcedData?.description ? JSON.parse(announcedData.description)?.mobile : ''
    return (
        <div>
            <Modal
                className="modal-round-corner"
                visible={isModalVisible}
                footer={null}
                //centered={true}
                // width={"60%"}
                closable={true}
                bodyStyle={{ height: "100%" }}
                onCancel={() => handleCancle()}
            >
                <ViewAnnounceMain>
                    <Text style={{ fontWeight: 'bold', fontSize: '22px', color: '#191919' }}>Details </Text>
                    {
                        announcedData?.typeAnnouncement === 'file' ?
                            <>
                                <ViewAnnounceDetails>
                                    <Text style={{ fontFamily: 'roboto', fontWeight: 'bold', color: '#191919', fontSize: "20px" }}>{announcedData?.title}</Text><br></br>
                                    {/* <div style={{ width: "70%", height: "75px", backgroundColor: "#FFFFFF", borderColor: "#E6E6E6", display: "flex", }}> */}

                                    <div className='' style={{ backgroundColor: "#E6E6E6", paddingLeft: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: "10px", }}>
                                        {announcedData?.fileName?.map((i) => (
                                            <div style={{ marginTop: '10px', }}>
                                                <div style={{ borderColor: "#E6E6E6", height: "40px", display: "flex", }}>
                                                    <div style={{ marginTop: "20px" }}>
                                                        <img src={file_image_1} />
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                                                        <Typography.Text style={{ fontFamily: 'roboto', marginTop: "15px", fontSize: "15px", fontWeight: "bold", width: 250 }} ellipsis={{ tooltip: true }}>{i.key}</Typography.Text>
                                                        {/* <div style={{ marginTop: "15px" ,cursor:"pointer",fontSize:"15px",fontWeight:"bold"}}></div> */}
                                                        <div style={{ color: "#AEAEAE", fontSize: "13px" }}>{i?.sizeInKB}KB</div>
                                                    </div>
                                                </div>
                                                <div style={{ borderColor: "#E6E6E6", color: "#1089FF", fontSize: "11px", fontWeight: "bold", textAlign: "right", cursor: "pointer" }} onClick={() => { downloadFile(i) }}>
                                                    <div style={{ marginTop: "-10px", marginRight: "20px" }}>
                                                        <DownloadOutlined style={{ color: "#1089FF", fontSize: "30px" }} />
                                                    </div>
                                                </div>

                                                <br></br>
                                            </div>


                                        ))}</div>



                                    {/* </div> */}


                                </ViewAnnounceDetails>
                            </> :
                            <ViewAnnounceDetails>
                                <Text style={{ fontFamily: 'roboto', fontWeight: 'bold', color: '#191919', fontSize: "20px" }}>{announcedData?.title}</Text><br></br>
                                <Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: "12px" }}>
                                    {/* <pre dangerouslySetInnerHTML={{ __html: htmlText }} /> */}
                                    {!IsJsonString(JSON.parse(announcedData?.description)?.mobile) && isHTML(JSON.parse(announcedData?.description)?.mobile) ? HtmlParser(JSON.parse(announcedData?.description)?.mobile) : null}
                                </Text>
                            </ViewAnnounceDetails>
                    }




                    <ViewAnnounceTargetAndTime>
                        <div>
                            <Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: "13px" }}>TARGET</Text><br></br>
                            <Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: "16px" }}>{announcedData?.target}</Text>
                        </div>
                        <div>
                            <Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: "13px" }}>ANNOUNCED ON</Text><br></br>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "175px" }}>
                                <Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: "16px" }}>{moment(announcedData?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')} </Text>
                                <div style={{ width: "6px", height: "6px", backgroundColor: "grey", borderRadius: "50%" }}></div>
                                <Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: "16px" }}> {moment(announcedData?.time, 'HH:mm:ss').format('hh:mm A')}</Text>
                            </div>
                        </div>
                    </ViewAnnounceTargetAndTime>

                </ViewAnnounceMain>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}>
                    <Button type='primary' style={{ width: '150px', marginRight: "8px", borderRadius: '16px', textAlign: 'center' }} onClick={() => setDeleteShow(true)}>DELETE</Button>

                    <Button type='primary' style={{ width: '150px', marginRight: "8px", borderRadius: '16px', textAlign: 'center' }} onClick={() => { setIsCloneModal(true) }}>CLONE</Button>

                </div>
                {deleteShow ?
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <div style={{ fontSize: "18px", fontWeight: "bold" }}>Confirm</div>
                        <div style={{ fontSize: "15px", color: "#636363" }}>Are you sure you want to delete ?</div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <div>
                                <Button

                                    loading={deleteButtonLoader} type='primary' style={{ fontWeight: "bold", width: '100%', borderRadius: '16px', textAlign: 'center' }} onClick={() => handleDelete()}>YES</Button>

                            </div>
                            <div style={{ marginLeft: "20px" }}>
                                <Button type='primary' style={{ fontWeight: "bold", width: '100%', borderRadius: '16px', textAlign: 'center' }} onClick={() => { setDeleteShow(false) }}>NO</Button>
                            </div>

                        </div>

                    </div> : null}
                {isCloneModal &&
                    <CloneModal
                        setIsModalVisible={setIsModalVisible}
                        isCloneModal={isCloneModal}
                        setIsCloneModal={setIsCloneModal}
                        SetKey={SetKey}
                        setIsSearch={setIsSearch}
                        setSort={setSort}
                        editData={announcedData}
                        edit='announce'
                        togglePageLoader={togglePageLoader}

                    />}
            </Modal>
        </div >
    )
}

const mapStateToProps = (state) => {
    const {
        standardList,
        sortFilter,
        searchFilter, announcement, storeKey

    } = state.announcement;
    return {
        standardList,
        sortFilter,
        searchFilter, announcement, storeKey
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchAnnouncement: (search, sort, callback) => dispatch(fetchAnnouncement(search, sort, callback)),
    deleteAnnouncement: (id, callback, successCallback) => dispatch(deleteAnnouncement(id, callback, successCallback)),
    storeAnnouncePage: (res) => dispatch(storeAnnouncePage(res)),


});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(ViewAnnouncement);

